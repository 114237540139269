import React from 'react';
import Logo from '../../logo/logo';
import './stub-header.scss';

const Header = () => (
  <div className="stub-header">
    <Logo />
  </div>
);

export default Header;
