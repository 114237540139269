import React from 'react';
import Image from './image';
import './stub-dog.scss';


const StubDog = () => (
  <div className="stub-dog">
    <Image className="stub-dog-img" />
  </div>
);

export default StubDog;
