import React from 'react';

import Header from '../components/stub/stub-header/stub-header';
import Body from '../components/stub/stub-body/stub-body';
import Dog from '../components/stub/stub-dog/stub-dog';

import '../styles/common.scss';
import '../components/stub/stub.scss';

const Page = () => (
  <div className="stub">
    <div className="page stub-page">
      <div className="stub-wrap">
        <Header />
        <Body />
      </div>

      <Dog />
    </div>
  </div>
);

export default Page;
