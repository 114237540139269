import React from 'react';
import Icon from '../../tz-icon/icon';
import Footer from '../stub-footer/stub-footer';
import './stub-body.scss';

const Body = () => (
  <div className="stub-body">
    <h1 className="stub-title">Сайт находится в разработке, и совсем скоро заработает для Вас!</h1>
    <h2 className="stub-subtitle">Открытие груминг-салона в начале февраля 2020!</h2>
    <div className="stub-socials">
      <p className="stub-socials-text">
        Подписывайтесь на нас в соцсетях,
        <br />
        чтобы первыми узнать об открытии
      </p>
      <div className="stub-socials-icons">
        <a
          href="https://instagram.com/tezi_grooming"
          target="_blank"
          rel="noopener noreferrer"
          className="stub-socials-link"
        >
          <Icon mod="ig" className="stub-socials-icon" />
        </a>
        <a
          href="https://vk.com/tezi_grooming"
          target="_blank"
          rel="noopener noreferrer"
          className="stub-socials-link"
        >
          <Icon mod="vk" className="stub-socials-icon" />
        </a>
        <a
          href="https://www.facebook.com/tezigrooming/"
          target="_blank"
          rel="noopener noreferrer"
          className="stub-socials-link"
        >
          <Icon mod="fb" className="stub-socials-icon" />
        </a>
      </div>
    </div>
    <Footer />
  </div>
);

export default Body;
