import React from 'react';
import Contact from '../../contact/contact';
import './stub-footer.scss';

const Footer = () => (
  <div className="stub-footer">
    <Contact
      className="stub-contact stub-footer__contact"
      href="tel:79661120222"
      mod="phone"
      text="+ 7 (966) 11-20-222"
    />
    <Contact
      className="stub-contact stub-footer__contact"
      href="mailto:info@tezi-grooming.com"
      mod="mail"
      text="info@tezi-grooming.com"
    />
  </div>
);

export default Footer;
